<template>
  <GradientBackLayout class="ProfilePageV2"
    :title="$filters.translate('profile_data').toUpperCase()"
    :shadowText="$t('profile_header')">
    <BoxShadow class="ProfilePageV2--Box">
      <div class="ProfilePageV2--Picture">
        <div class="ProfilePageV2--image" :style="{'background-image':'url(\''+userProfile.picture+'\')'}">
          <div class="ProfilePageV2--uploadImageLogo" v-on:click="openPhotoUploader()">
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.5354 12.5C20.0423 12.5 19.6426 12.8998 19.6426 13.3929V22.3213C19.6426 22.8144 19.2428 23.2142 18.7497 23.2142H2.67854C2.18541 23.2142 1.78567 22.8144 1.78567 22.3213V4.46442C1.78567 3.9713 2.18541 3.57156 2.67854 3.57156H13.3927C13.8858 3.57156 14.2855 3.17182 14.2855 2.6787C14.2855 2.18558 13.8858 1.78589 13.3927 1.78589H2.67854C1.19922 1.78589 0 2.98511 0 4.46442V22.3213C0 23.8006 1.19922 24.9999 2.67854 24.9999H18.7498C20.2291 24.9999 21.4283 23.8006 21.4283 22.3213V13.3928C21.4283 12.8998 21.0285 12.5 20.5354 12.5Z" fill="url(#paint0_linear)"/>
              <path d="M23.9991 1.0014C23.358 0.36023 22.4885 9.26008e-05 21.5818 0.000197231C20.6746 -0.00241853 19.8042 0.358347 19.1649 1.00198L7.40427 12.7616C7.30671 12.8599 7.2331 12.9794 7.1891 13.1107L5.40343 18.4678C5.24758 18.9356 5.50052 19.4412 5.96838 19.597C6.05915 19.6272 6.1542 19.6427 6.24983 19.6428C6.34568 19.6426 6.44094 19.6272 6.53197 19.5972L11.8891 17.8116C12.0206 17.7676 12.1402 17.6937 12.2382 17.5955L23.9987 5.83496C25.3335 4.5003 25.3337 2.33623 23.9991 1.0014Z" fill="url(#paint1_linear)"/>
              <defs>
              <linearGradient id="paint0_linear" x1="7.98266e-08" y1="13.3929" x2="21.4283" y2="13.3929" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              <linearGradient id="paint1_linear" x1="5.35742" y1="9.82147" x2="24.9999" y2="9.82147" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF1564"/>
              <stop offset="1" stop-color="#FD7328"/>
              </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <a-tooltip  placement="right">
            <template #title>{{$t('nickname_rgpd_warning')}}</template>
            <div class="ProfilePageV2--Nickname">
                <CommunityInput ref="nickname" type="text" disabled v-model="editForm.nickname" />
            </div>
        </a-tooltip>
        <div class="ProfilePageV2--Info">
          <div class="ProfilePageV2--InfoBox">
            <p>{{userProfile.points}}</p>
            <p>LABs</p>
          </div>
          <div class="ProfilePageV2--InfoBox">
            <p>{{userProfile.commentsNum}}</p>
            <p>{{$t('comments')}}</p>
          </div>
        </div>
      </div>
      <div class="ProfilePageV2--Form">
        <div class="ProfilePageV2--FormInputs">
          <CommunityInput ref="name" type="text" :placeholder="$t('name')" v-model="editForm.name" :required="true" v-on:key-up="handleChangeForm($event)"/>
          <CommunityInput ref="surname" type="text" :placeholder="$t('surname')" v-model="editForm.surname1" :required="true" v-on:key-up="handleChangeForm($event)"/>
          <CommunityInput ref="email" type="email" :disabled="true" :placeholder="$t('profile_personalization_email')" v-model="editForm.email" :required="true" v-on:key-up="handleChangeForm($event)"/>
          <select-input
            id="gender"
            :selected="String(editForm.gender)"
            :value="[
              { id: 'M', value: $t('gender_M')    },
              { id: 'W', value: $t('gender_W')  }
            ]"
            @change="handleChange" />
            <CommunityInput
            ref="bornDate"
            v-model="editForm.bornDate"
            v-on:change="handleChangeForm($event)"
            :required="true"
            :placeholder="$t('profile_personalization_borndate')"
            :lang="communityInfo.language ? communityInfo.language : 'es'"
            type="dateTesterProfile"
            format="dd-MM-yyyy"
            :yearRange="validBornDates" />
          <select-input
            id="province"
            :selected="String(editForm.province.id)"
            :value="provinces"
            @change="handleChangeProvince" />
        </div>
        <Separator />
        <div class="ProfilePageV2--Success" v-if="formSuccess">
          {{$t('success_profile_updated')}}
        </div>
        <div class="ProfilePageV2--Error" v-show="formError">
          <CommunityErrors :errors="[formError]" />
        </div>
        <div class="ProfilePageV2--Actions">
          <CommunityButton :disabled="disableSubmitButton" v-on:click="handleSubmit($event)">{{$t('save')}}</CommunityButton>
        </div>
        <div class="ProfilePageV2--FormInputs">
          <CommunityTextInput ref="currentPass" type="password" :placeholder="$t('password_current')" v-model="changePassForm.currentPass" :required="true"/>
          <CommunityTextInput ref="newPass" type="password" :placeholder="$t('password_new')" v-model="changePassForm.newPass" :required="true"/>
          <CommunityTextInput ref="confirmNewPass" type="password" :placeholder="$t('password_confirm')" v-model="changePassForm.confirmNewPass" :required="true"/>
        </div>
        <Separator />
        <div class="ProfilePageV2--Success" v-if="formPassSuccess">
          {{formPassSuccess}}
        </div>
        <div class="ProfilePageV2--Error" v-show="passError">
          <CommunityErrors :errors="[passError]" />
        </div>
        <div class="ProfilePageV2--Actions">
          <CommunityButton :disabled="disablePasswordSubmit" v-on:click="handleChangePassword()">{{$t('action_modify')}}</CommunityButton>
        </div>
      </div>
    </BoxShadow>
    <photo-uploader
      :show="showPhotoUploader"
      @close-modal="showPhotoUploader = false"
      @photo-saved="savePhoto"
    ></photo-uploader>
  </GradientBackLayout>
</template>

<script>
  import Swal from 'sweetalert2'
  import CommunityInput           from '@/components/CommunityInput.vue'
  import CommunityErrors          from '@/components/CommunityErrors.vue'
  import PhotoUploader            from '@/components/PhotoUploader.vue';
  import SelectInput              from '@/components/SelectInput.vue';
  import TextInputWithLoader      from '@/components/TextInputWithLoader.vue';
  import BoxShadow                from '@/components/BoxShadow.vue';
  import CommunityTextInput       from '@/components/CommunityTextInput.vue';
  import Separator                from '@/components/Separator.vue';
  import GradientBackLayout       from '@/layouts/GradientBackLayout'
  import CommunityButton          from '@/components/CommunityButton';
  import Spinner                  from '~/components/Spinner.vue';
  import store                    from '@/store';
  import { mapGetters }           from 'vuex';
  import moment                   from 'moment';
  import {
    fetchUserProfile,
    updateProvince,
    isNicknameAvailable
  }  from '@/utils';
  import {
    USER_PUT_PROFILE_CHANGES,
    USER_FETCH_PROFILE,
    USER_AUTH_LOGOUT,
    USER_GET_NICKNAME_AVAILABILITY,
    USER_CHANGE_PASSWORD,
  } from '@/store/user.module.ts';

  export default {
    name: 'ProfilePage',

    components: {
      GradientBackLayout,
      CommunityButton,
      Spinner,
      BoxShadow,
      CommunityTextInput,
      Separator,
      SelectInput,
      TextInputWithLoader,
      PhotoUploader,
      CommunityErrors,
      CommunityInput
    },

    data() {
      return {
        showPhotoUploader:false,
        disableSubmitButton:false,
        formSuccess:false,
        editForm      : {
          name      : "",
          surname1  : "",
          email     : "",
          gender    : "M",
          bornDate  : "",
          province  : {},
          picture   : "",
          commentsNum: 0
        },
        changePassForm: {
          currentPass     : '',
          newPass         : '',
          confirmNewPass  : ''
        },
        formPassSuccess:false,
        passError: false,
        formError: false
      };
    },

    computed: {
      ...mapGetters([
        'userProfile',
        'provinces',
        'theme',
        'origin',
        'isUser',
        'isClient',
        'isModerator',
        'isObservator',
        'communityInfo',
      ]),
      disablePasswordSubmit ( ) {
        const { currentPass, newPass, confirmNewPass } = this.changePassForm;
        return Boolean(
          this.invalidNewPass ||
          !currentPass || !newPass || !confirmNewPass
        );
      },

      invalidNewPass ( ) {
        const { newPass, confirmNewPass } = this.changePassForm;
        const invalid = newPass && confirmNewPass && newPass !== confirmNewPass;

        this.passError = invalid && this.$t('error_password_not_same')

        return invalid;
      },
      validBornDates(){
        const date = new Date();
        const year = date.getFullYear();
        const limit_old = year - 100;
        const limit_young = year - 16;
        return [limit_old, limit_young]
      }
    },

    methods: {
      disableSubmit(){
        let hasError = false
        if(this.$refs.name.validate() ||
        this.$refs.surname.validate() ||
        this.$refs.bornDate.validateDate()){
          hasError = true
        }
        if (this.editForm.bornDate == null) {
          hasError = true
        }
        return this.disableSubmitButton = hasError
      },
      handleChange ( value, id ) {
          this.editForm[id] = value
      },

      handleChangeProvince ( value, id ) {
        if (isNaN(value)) return
        this.editForm.province = {
          elementId: value,
          id: value,
          lovName: "provinces"
        }
      },

      handleChangeForm(e){
        this.disableSubmit()
      },

      async handleSubmit ( e ) {
        this.formError = false
        this.loading = true;
        let bornDate = moment(this.editForm.bornDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        bornDate = bornDate.substring(0, bornDate.length - 3) + bornDate.substr(-2);
        store.dispatch( USER_PUT_PROFILE_CHANGES, {
          response: { ...this.editForm, bornDate: bornDate}
        } )
          .then( ( { data } ) => {
            this.formSuccess  = true;
            this.formError = false
            setTimeout( (  ) => {
              this.formSuccess = false;
            }, 5000 );
            this.loading      = false;
            store.dispatch( USER_FETCH_PROFILE,{communityName: this.$route.params.origin});
          } )
          .catch( error => {
            this.formError = this.$t('error_profile_date')
            this.loading  = false;
          } );
      },
      async handleChangePassword(){
        store.dispatch( USER_CHANGE_PASSWORD, {
          response: {
            oldPassword : this.changePassForm.currentPass,
            newPassword : this.changePassForm.newPass
          }
        } )
          .then(
            (
              ( { data } ) => {
                const errors = data && data.error === 'incorrectPassword';
                if ( errors ) return this.passError = this.$t('error_current_password_wrong_1')
                this.formPassSuccess  = this.$t('success_password_modified');
                this.changePassForm = {
                  currentPass:'',
                  newPass:'',
                  confirmNewPass:''
                }
                setTimeout( (  ) => {
                  this.formPassSuccess = '';
                }, 5000 );
              }
            ).bind( this )
          )
          .catch( error => {
            this.loading  = false;
            if (error.response.status === 400) {
              if (error.response.data.error == 'incorrectPassword') { 
                this.passError = this.$t('error_current_password_wrong_2')
              } else if (error.response.data.error == 'json validation error') {
                this.passError = this.$t('error_new_password_not_valid', {simbols:'(@ $ ! % * ? &)'})
              }
            } else {
              this.passError = this.$t('error_connection')
            }
          } );
      },
      savePhoto ( path ) {
        this.editForm.picture = path;
        this.showPhotoUploader = false;
        this.handleSubmit(null);
      },
      openPhotoUploader(){
        this.disableSubmit()
        if(this.disableSubmitButton == true) {
          Swal.fire({
            text:this.$t('msg_profile_image_error'),
            icon:"info",
            customClass:{popup:"swal-customInfo"},
          })
        }
        else this.showPhotoUploader = true
      }
    },

    async created ( ) {
      await fetchUserProfile( this )
    },
  }
</script>

<style lang="scss">
.Panel--profile{
  background: linear-gradient(269.66deg, rgba(0, 0, 0, 0.4) 3.24%, transparent 96.38%)!important;
}
.ProfilePageV2{
  .ProfilePageV2--Box{
    @media screen and ( max-width: 490px ) {
      margin-top: 40px;
    }
  }
  &--FormInputs{
    display: flex;
    flex-wrap: wrap;
    .CommunityInput:first-of-type{
      width: 100%;
    }
    .CommunityInput{
      padding: .5rem;
      width: 50%;
      &--Error{
        bottom: -9px;
      }
      @media only screen and ( max-width: 768px ) {
        width: 100%;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.ProfilePageV2{
  &--Box{
    padding: 10px;
    display: grid;
    grid-template-columns: 245px auto;
    grid-gap: 50px;
    @media only screen and ( max-width: 670px ) {
      display: flex;
      flex-direction: column;
    }
  }
  &--image{
    height: 294px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 4px solid #F2F2F2;
    position: relative;
  }
  &--uploadImageLogo{
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &--Nickname{
    .CommunityInput{
      background-color: white;
    }
  }
  &--Info{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5px;
    position: relative;
  }
  &--InfoBox{
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    border: 2px solid #FFFFFF;
    box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.11);
    padding: 5px 10px;
    &:first-child{
      color: white;
    }
    &:last-child{
      background: white;
    }
    p{
      font-weight: bold;
      text-align: center;
      margin:0;
    }
    p:first-child{
      font-size: 49px;
      line-height: 50px;
    }
    p:last-child{
      font-size: 12px;
    }
  }
  &--Actions{
    margin-bottom: 30px;
  }
  &--Success{
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    padding: 10px 15px;
    margin-bottom: 10px;
  }
  &--Error{
    margin-bottom: 10px;
  }
}
</style>
